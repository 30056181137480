import { DialogController } from 'aurelia-dialog';
import { Pipelines } from 'services/pipelines';
import moment from 'moment';

export class EditPipelinePerson {
    static inject = [DialogController, Pipelines];
    dialogController;
    _pipelines;

    name;
    color;
    deleteText = 'purge';

    constructor(dialogController, pipelines) {
        this.dialogController = dialogController;
        this._pipelines = pipelines;
    }

    activate(model) {
        this.pipelineId = model.pipelineId;
        this.memberId = model.pipelinePerson.member.id;
        this.name = model.pipelinePerson.member.fullName;
        this.isArchived = model.pipelinePerson.isArchived;
        this.originalEnteredStageDate = model.pipelinePerson.enteredStageDate ? model.pipelinePerson.enteredStageDate.substring(0, 10) : '';
        this.enteredStageDate = model.pipelinePerson.enteredStageDate ? model.pipelinePerson.enteredStageDate.substring(0, 10) : '';
        this.metadata = model.pipelinePerson.metadata;
    }

    async save() {
        try {
            const metadata = Object.assign({}, this.metadata, { enteredStageDate: this.enteredStageDate });
            const updatedMetadata = await this._pipelines.savePipelinePerson(this.pipelineId, this.memberId, metadata);
            const enteredStageDate = this.originalEnteredStageDate === this.enteredStageDate ? null : moment.utc(this.enteredStageDate).toISOString();
            this.dialogController.ok({ metadata: updatedMetadata, enteredStageDate });
        } catch (err) {
            console.log(err);
        }
    }

    async archive() {
        try {
            await this._pipelines.removeFromPipeline(this.pipelineId, this.memberId);
            this.dialogController.ok({ archived: true });
        } catch (err) {
            console.log(err);
        }
    }

    async purge() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteText = 'purge-confirm';
            return;
        }

        this.showDeleteConfirm = false;
        if (this.deleting) return;
        try {
            this.deleting = true;
            await this._pipelines.purgePipeline(this.pipelineId, this.memberId);
            this.dialogController.ok({ deleted: true });
        } catch(err) {
            console.log(err);
            this.resetDelete();
        } finally {
            this.deleting = false;
        }
    }

    async restore() {
        if (this.restoring) return;
        try {
            this.restoring = true;
            await this._pipelines.restoreToPipeline(this.pipelineId, this.memberId);
            this.dialogController.ok({ restored: true });
        } catch(err) {
            console.log(err);
        } finally {
            this.restoring = false;
        }
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteText = 'purge';
    }
}
