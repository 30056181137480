import { observable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { Security } from 'common/security';
import { Pipelines } from 'services/pipelines';
import { Carriers } from 'services/carriers';
import { I18n } from 'common/i18n';
import { AGENT_PROPERTY } from 'common/constants';

export class EditPipeline {
    static inject = [DialogController, Pipelines, Carriers, Security, I18n, NewInstance.of(ValidationController)];
    dialogController;
    validationController;
    _pipelines;
    _carriers;
    security;
    _i18n;

    pipelineTemplates = [];
    @observable pipelineTemplate;

    name;
    description;
    color;
    code;
    carriers;
    carrierId;
    showAgentProperties;
    
    agentPropertyOptions = AGENT_PROPERTY.tagOptions();
    agentPropertyTagOptions = [];
    agentProperties = [];
    agentPropertyTags = [];

    constructor(dialogController, pipelines, carriers, security, i18n, validationController) {
        this.dialogController = dialogController;
        this.security = security;
        this._pipelines = pipelines;
        this._carriers = carriers;
        this._i18n = i18n;

		this.validationController = validationController;
		this.validationController.addRenderer(new BootstrapFormValidationRenderer());

		ValidationRules
            .ensure('name').required()
            .on(this);
    }

    async activate(model) {
        this.pipeline = model.pipeline;
        this.titleKey = this.pipeline.id ? 'edit-pipeline' : 'add-pipeline';
        this.name = '';
        this.description = '';
        this.code = null;
        this.carrierId = null;
        this.showAgentProperties = [];
        this.color = '';
        this.step = '';
        if (this.pipeline.id) {
            this.name = this.pipeline.name;
            this.description = this.pipeline.description;
            this.code = this.pipeline.code;
            this.carrierId = this.pipeline.carrierId;
            this.showAgentProperties = this.pipeline.showAgentProperties || [];
            this.color = this.pipeline.color;
            this.setStep('create-edit', false);
        } else {
            this.pipelineTemplates = await this._pipelines.availableTemplates();
            this.setStep(this.pipelineTemplates.length > 0 ? 'select' : 'create-edit');
        }

        this.agentPropertyTagOptions = [];
        this.agentPropertyOptions.forEach(o => {
            if (!o.translation) o.translation = this._i18n.tr(o.translationKey);
            this.agentPropertyTagOptions.push(o.translation);
        });

        this.showAgentProperties.forEach(ap => {
            const option = this.agentPropertyOptions.find(x => x.id === ap);
            if (!option) return;
            this.agentPropertyTags.push(option.translation);
        });

        this.carriers = await this._carriers.all();
    }

    setStep(step, clearName = true) {
        this.step = step;
        if (clearName) this.name = '';
        if (step === 'create-edit') this.focusName = true;
    }

    agentPropertiesChanged(tags) {
        const properties = [];
        tags.forEach(t => {
            let option = this.agentPropertyOptions.find(x => x.translation.toLowerCase() === t.toLowerCase());
            if (!option) return;
            properties.push(option.id);
        });
        this.showAgentProperties = properties;
    }

    async createFromTemplate(pipelineTemplate) {
        this.pipelineTemplate = pipelineTemplate;
        this.name = this.pipelineTemplate.name;
        await this.save();
    }

    async save() {
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;

            let pipeline;
            if (this.pipeline.id) {
                pipeline = await this._pipelines.editPipeline(this.pipeline.id, this.name, this.description, this.color, this.showAgentProperties, this.code, this.carrierId);
            } else {
                if (this.pipelineTemplate) {
                    pipeline = await this._pipelines.createPipelineFromTemplate(this.pipelineTemplate.id);
                } else {
                    pipeline = await this._pipelines.createPipeline(this.name, this.description, this.color, this.showAgentProperties, this.code, this.carrierId);
                }
            }
            this.dialogController.ok({ pipeline });
        } catch (err) {
            console.log(err);
        }
    }
}
