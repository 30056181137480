import { bindable, observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Pipelines } from 'services/pipelines';
import { TemplatingEngine } from 'aurelia-templating';
import { LpfnUtil } from 'common/utils';
import { DialogService } from 'aurelia-dialog';
import { Notifier } from 'common/ui';
import { SharePipeline } from './dialogs/share-pipeline';
import { EditPipeline } from './dialogs/edit-pipeline';
import { CopyPipeline } from './dialogs/copy-pipeline';
import { ReorderPipeline } from './dialogs/reorder-pipeline';
import { EditStage } from './dialogs/edit-stage';
import { EditPipelinePerson } from './dialogs/edit-pipeline-person';
import { ConfirmDialog } from 'common/dialogs/confirm/confirm-dialog';
import { c } from 'common/common';
import moment from 'moment';
PLATFORM.moduleName('./dialogs/share-pipeline');
PLATFORM.moduleName('./dialogs/edit-pipeline');
PLATFORM.moduleName('./dialogs/copy-pipeline');
PLATFORM.moduleName('./dialogs/reorder-pipeline');
PLATFORM.moduleName('./dialogs/edit-stage');
PLATFORM.moduleName('./dialogs/edit-pipeline-person');
PLATFORM.moduleName('common/dialogs/confirm/confirm-dialog');

export class Stages {
    static inject = [Element, EventAggregator, Security, Pipelines, TemplatingEngine, DialogService, Notifier]
    _element;
    _ea;
    _security;
    _templatingEngine;
    _pipelines;
    _dialogService;
    _notifier;

    @bindable pipelineId;
    @observable addAgent;

    pipeline = { stages: [] };
    backlog = [];
    _itemViews = [];

    archivedAgents = false;
    VIEWS = {
        standard: 'view-standard',
        compact: 'view-compact',
        minimized: 'view-minimized',
    };
    view = this.VIEWS.standard;

    pipelineActions = [];
    _handlers = [];
    reordering = false;

    constructor(element, ea, security, pipelines, templatingEngine, dialogService, notifier) {
        this._element = element;
        this._ea = ea;
        this._security = security;
        this._pipelines = pipelines;
        this._templatingEngine = templatingEngine;
        this._dialogService = dialogService;
        this._notifier = notifier;
        this.id = c.Helpers.uniqueId();
    }

    async attached() {
        this._isAttached = true;
        if (this.pipelineId) await this._load();
        this._handlers.push(this._ea.subscribe('dnd:didEnd', () => {
            window.setTimeout(() => this._onAfterReorder(), 0);
        }));
        this._handlers.push(this._ea.subscribe('dnd:willStart', () => {
            this.reordering = true;
            this._onBeforeReorder();
        }));
        LpfnUtil.addResizeHandler(this._onResize);
    }

    detached() {
        this._isAttached = false;
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
        this.dispose(true);
        LpfnUtil.removeResizeHandler(this._setBigButtonView);
    }

    _onResize = () => {
        this.stageMaxHeightCss = undefined;
        this._setStageHeight();
    }

    _onBeforeReorder() {
        try {
            this.dispose(false);
        } catch (err) {
            console.log(err);
        }
    }

    async _onAfterReorder() {
        try {
            const orderedIds = [];
            this.pipeline.stages.forEach(sl => {
                orderedIds.push(sl.id);
            });
            await this._pipelines.setStageOrdering(this.pipeline.id, orderedIds);
            this.pipeline.stages.forEach(sl => {
                const slEl = document.getElementById(`sl-container-${sl.id}`);
                const gridStackEl = document.createElement('div');
                gridStackEl.id = sl.id;
                gridStackEl.style.backgroundColor = sl.stageBackgroundColor;
                gridStackEl.style.borderLeft = `1px solid ${sl.placeholderColor}`;
                gridStackEl.style.borderRight = `1px solid ${sl.placeholderColor}`;
                gridStackEl.classList.add('grid-stack', `grid-stack-${this.id}`, 'lpfn-tage');
                slEl.appendChild(gridStackEl);
            });
            this._initializeGrids();
            this.stageMaxHeightCss = undefined;
            this._setStageHeight();
        } catch (err) {
            console.log(err);
        }
    }

    dispose(clearPipeline = true) {
        try {
            this.archivedAgents = false;
            this.orderByKey = undefined;
            this.sortDirection = undefined;
            this._itemViews.forEach(iv => {
                try {
                    iv.view.detached();
                } catch (vErr) {
                    console.log(vErr);
                }
            });
            if (this._grids) {
                try {
                    this._grids.forEach(g => {
                        g.offAll();
                        g.destroy(!clearPipeline);
                    });
                } catch (err2) { /* Do nothing, bug in gridstack where it tries to set the height after removing the grid */ }
                this._grids = undefined;
            }
            if (clearPipeline) {
                this.pipeline = { stages: [] };
            }
        } catch (err) {
            console.log(err);
        }
    }

    pipelineIdChanged() {
        if (this._isAttached) this._load();
    }

    async _load() {
        if (!this.pipelineId) return;
        try {
            await this._loadPipeline();
            this._initializeGrids();
            this._setStageHeight();
        } catch (err) {
            console.log(err);
        }
    }

    _setStageHeight() {
        try {
            if (!this.pipeline.stages.length) return;
            if (this.stageMaxHeightCss) return;
            const firstEl = document.getElementById(this.pipeline.stages[0].id);
            const offset = LpfnUtil.offset(firstEl);
            const fullHeight = LpfnUtil.getDocumentHeight();
            const footerEl = document.getElementById('kt_app_footer');
            const footerHeight = LpfnUtil.actualHeight(footerEl);
            const maxHeight = fullHeight - footerHeight - offset.top - 50; // buffer
            if (maxHeight < 250) {
                this.pipeline.stages.forEach(sl => {
                    document.getElementById(sl.id).style.maxHeight = '500px';
                });
                return;
            }
            this.stageMaxHeightCss = `${maxHeight}px`;
            this.pipeline.stages.forEach(sl => {
                document.getElementById(sl.id).style.maxHeight = this.stageMaxHeightCss;
            });
        } catch (err) {
            console.log(err);
        }
    }

    async _loadPipeline() {
        if (!this.pipelineId) return;
        try {
            this.orderByKey = undefined;
            this.sortDirection = undefined;
            this.pipeline = await this._pipelines.pipeline(this.pipelineId);

            this.pipelineActions = [];
            if (this.pipeline.canReorderStageCards) this.pipelineActions.push({ key: 'reorder-pipeline', name: 'reorder-pipeline', icon: 'fa-duotone fa-arrow-up-short-wide' });
            if (this.pipeline.canEdit) {
                if (this._security.isAdmin) this.pipelineActions.push({ key: 'share-pipeline', name: 'share-pipeline', icon: 'fa-duotone fa-handshake-simple' });
                this.pipelineActions.push({ key: 'edit-pipeline', name: 'edit-pipeline', icon: 'fa-duotone fa-pencil' });
                this.pipelineActions.push({ key: 'copy-pipeline', name: 'copy-pipeline', icon: 'fa-duotone fa-clone' });
                this.pipelineActions.push({ key: 'add-stage', name: 'add-stage', icon: 'fa-duotone fa-line-columns' });
            }
            if (!this.archivedAgents) this.pipelineActions.push({ key: 'archived-agents', name: 'view-archived-pipeline', icon: 'fa-duotone fa-box-archive' });
            if (this.archivedAgents) this.pipelineActions.push({ key: 'current-agents', name: 'view-current-pipeline', icon: 'fa-duotone fa-wave-pulse' });
            this.pipelineActions.push({ key: this.VIEWS.standard, name: 'pipeline-view-standard', icon: 'fa-duotone fa-arrows-maximize' });
            this.pipelineActions.push({ key: this.VIEWS.compact, name: 'pipeline-view-compact', icon: 'fa-duotone fa-arrows-minimize' });
            this.pipelineActions.push({ key: this.VIEWS.minimized, name: 'pipeline-view-minimized', icon: 'fa-duotone fa-arrows-to-dot' });
        
            const loaders = [
                this._pipelines.pipelineBacklog(this.pipelineId),
            ];
            this.pipeline.stages.forEach(sl => {
                this._addActionsToStage(sl);
                loaders.push(this._pipelines.membersInStage(this.pipelineId, sl.id, this.archivedAgents));
            });
            const data = await Promise.all(loaders);
            this.backlog = data[0];
            let dataIdx = 1;
            this.pipeline.stages.forEach(sl => {
                sl.members = data[dataIdx] || [];
                sl.backgroundColor = LpfnUtil.hexToRgbString(sl.color, 0.05);
                sl.placeholderColor = LpfnUtil.hexToRgbString(sl.color, 0.5, '#D3D3D3');
                sl.stageBackgroundColor = LpfnUtil.hexToRgbString(sl.color, 0.08, '#D3D3D3');
                dataIdx++;
            });
            if (!this.pipeline.stages.length && this.pipeline.canEdit) this._addStage();
        } catch (err) {
            console.log(err);
        }
    }

    _addEventsToGrid(grid, i) {
        grid
            .on('added', async (e, items) => {
                try {
                    if (this._waitForAddedEventForMemberId === items[0].id) {
                        this._waitForAddedEventForMemberId = undefined;
                    }
                    const toStageId = grid.el.id ?? null;
                    const el = items[0].el; // the initial load may contain multiple - still just need to check the first to know if this is the initial load
                    const isInitialized = LpfnUtil.hasClass(el, 'lpfn-initialized');
                    const agentId = items[0].id || el.id;
                    const backlogIndex = this.backlog.findIndex(x => x.id === agentId);
                    let currentStageIndex = -1;
                    let toStageIndex = -1;
                    let currentStageMemberIndex = -1;
                    if (backlogIndex < 0) {
                        toStageIndex = this.pipeline.stages.findIndex(x => x.id === toStageId);
                        currentStageIndex = 0;
                        for (let sl of this.pipeline.stages) {
                            currentStageMemberIndex = sl.members.findIndex(x => x.member.id === agentId);
                            if (currentStageMemberIndex >= 0) break;
                            currentStageIndex++;
                        }
                    }
                    let updatedMember;
                    if (currentStageIndex !== toStageIndex) {
                        updatedMember = await this._pipelines.changeStage(this.pipelineId, agentId, toStageId);
                        if (currentStageIndex >= 0 && toStageIndex >= 0) {
                            this.pipeline.stages[toStageIndex].members.push(updatedMember);
                        }
                        if (backlogIndex >= 0) {
                            this.backlog.splice(backlogIndex, 1);
                        } else {
                            if (currentStageMemberIndex >= 0) {
                                this.pipeline.stages[currentStageIndex].members.splice(currentStageMemberIndex, 1);
                            }
                        }
                    }
                    let currentStage = currentStageIndex >= 0 ? this.pipeline.stages[currentStageIndex] : undefined;
                    const stageColor = currentStage?.color || '#ccc';

                    if (isInitialized) {
                        await this._reorderMembers(toStageId, e.srcElement);
                        if (updatedMember) {
                            this._setAgentPipelineCard(updatedMember.member.id, undefined, updatedMember.metadata?.color, updatedMember.enteredStageDate, currentStage);
                            this._updateViewContext(updatedMember);
                        }
                        return;
                    }
                    // Need to initialize the widget
                    for (let item of items) {
                        let contentEl = item.el.querySelector('.grid-stack-item-content');
                        contentEl.id = `grid-stack-item-${item.id}`;
                        contentEl.classList.add('show-on-hover-trigger');
                        const member = this._memberFromPipeline(item.id);
                        if (member.metadata) this._setAgentPipelineCard(item.id, contentEl, member.metadata.color);
                        const gradientStops = this._gradientStops(member.percentageOfExpectedTimeInStage);
                        const enteredStage = member.enteredStageDate ? moment(member.enteredStageDate).format('M/D') : '';
                        item.el.classList.add('lpfn-initialized');
                        item.el.classList.add('lpfn-tage-item');
                        let innerHTML = '';
                        if (this.pipeline.canEdit) innerHTML += '<button class="lpfn-pipeline-member-editor show-on-hover lpfn-icon-button" click.delegate="editPipelinePerson(pipelinePerson)"><i class="fa-solid fa-ellipsis"></i></button>';
                        innerHTML += `<member-name member.bind="member" show-badges.bind="false" show-actions.bind="true" show-properties.bind="showProperties" carrier-id.bind="carrierId"></member-name><div class="lpfn-pipeline-stage-duration" id="pipeline-duration-${member.member.id}"><span id="pipeline-entered-stage-date-${member.member.id}">${enteredStage}</span><div id="pipeline-stage-length-${member.member.id}" title="${member.percentageOfExpectedTimeInStage * 100}%" class="lpfn-pipeline-stage-duration-percentage" style="background: ${stageColor}; background: linear-gradient(90deg, ${stageColor} 0%, ${stageColor} ${gradientStops.first}%, transparent ${gradientStops.second}%, transparent 100%);"></div></div>`;
                        contentEl.innerHTML = innerHTML;
                        const view = this._templatingEngine.enhance({ element: item.el, bindingContext: { member: member.member, pipelinePerson: member, metadata: member.metadata, showProperties: this.pipeline.showAgentProperties, carrierId: this.pipeline.carrierId, editPipelinePerson: (pipelinePerson) => this._editPipelinePerson(pipelinePerson) } });
                        this._itemViews.push({ id: member.member.id, view });
                    }
                } catch (err) {
                    console.log(err);
                }
            })
            .on('change', async (e, items) => {
                try {
                    await this._reorderMembers(grid.el.id, e.srcElement);
                } catch (err) {
                    console.log(err);
                }
            })
            .on('removed', async (e, items) => {
                try {
                    this._waitForAddedEventForMemberId = items[0].id;
                    await this._reorderMembers(grid.el.id, e.srcElement);
                    window.setTimeout(() => this._checkIfRemovedFromPipeline(), 500);
                } catch (err) {
                    console.log(err);
                }
            })
        ;
    }

    _updateViewContext(pipelinePerson) {
        try {
            const itemViewIndex = this._itemViews.findIndex(x => x.id === pipelinePerson.member.id);
            if (itemViewIndex >= 0) {
                this._itemViews[itemViewIndex].view.bindingContext.member = pipelinePerson.member;
                this._itemViews[itemViewIndex].view.bindingContext.pipelinePerson = pipelinePerson;
                this._itemViews[itemViewIndex].view.bindingContext.metadata = pipelinePerson.metadata;
            } else {
                console.log(this._itemViews);
            }
        } catch (err) {
            console.log(err);
        }
    }

    _gradientStops(percentageOfExpectedTimeInStage) {
        let stop1 = percentageOfExpectedTimeInStage - 0.05;
        let stop2 = percentageOfExpectedTimeInStage + 0.05;
        if (stop1 < 0.01) stop1 = 0.01;
        if (stop2 > 1) stop2 = 1;
        if (percentageOfExpectedTimeInStage === 1) {
            stop1 = 1;
            stop2 = 1;
        }
        stop1 *= 100;
        stop2 *= 100;
        return { first: stop1, second: stop2 };
    }

    _setAgentPipelineCard(agentId, el, agentColor, enteredStageDate, stage) {
        try {
            if (!el) el = document.getElementById(`grid-stack-item-${agentId}`);
            let foregroundColor = 'inherit';
            let backgroundColor = undefined;
            let borderColor = LpfnUtil.getCssVariableValue('--kt-border-color');
            if (agentColor) {
                backgroundColor = LpfnUtil.hexToRgbString(agentColor, 0.05);
                borderColor = agentColor;
            }
            el.style.backgroundColor = backgroundColor;
            el.style.borderColor = borderColor;
            el.style.color = foregroundColor;

            if (enteredStageDate) {
                const enteredStage = moment.utc(enteredStageDate);
                const dateEl = document.getElementById(`pipeline-entered-stage-date-${agentId}`);
                if (dateEl) dateEl.innerHTML = enteredStage.format('M/D');

                if (stage && stage.expectedDaysInStage) {
                    const lengthEl = document.getElementById(`pipeline-stage-length-${agentId}`);
                    if (lengthEl) {
                        const stageHours = stage.expectedDaysInStage * 24;
                        const hoursInStage = moment.duration(moment().diff(enteredStage)).asHours();
                        const percentageOfExpectedTimeInStage = hoursInStage > stageHours ? 1 : hoursInStage / stageHours;
                        lengthEl.title = `${c.Helpers.round(percentageOfExpectedTimeInStage * 100, 0)}%`;
                        const stageColor = stage?.color || '#ccc';
                        const gradientStops = this._gradientStops(percentageOfExpectedTimeInStage);
                        lengthEl.style.background = `linear-gradient(90deg, ${stageColor} 0%, ${stageColor} ${gradientStops.first}%, transparent ${gradientStops.second}%, transparent 100%)`;
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    _editPipelinePerson(pipelinePerson) {
        if (!this.pipeline.canEdit) return;
        try {
            const model = { pipelineId: this.pipeline.id, pipelinePerson };
            this._dialogService.open({ viewModel: EditPipelinePerson, model, ignoreTransitions: true }).whenClosed(async(response) => {
                if (response.wasCancelled) return;
                if (response.output.deleted || response.output.restored || response.output.archived) {
                    this._load();
                    return;
                }
                const stage = this._memberStage(pipelinePerson.member.id);
                this._setAgentPipelineCard(pipelinePerson.member.id, undefined, response.output.metadata.color, response.output.enteredStageDate, stage);
                if (response.output.enteredStageDate) pipelinePerson.enteredStageDate = response.output.enteredStageDate;
                this._updateViewContext(pipelinePerson);
            });
        } catch (err) {
            console.log(err);
        }
    }

    async _checkIfRemovedFromPipeline() {
        if (!this._waitForAddedEventForMemberId) return;
        this._removeMemberFromPipeline(this._waitForAddedEventForMemberId);
        this._waitForAddedEventForMemberId = undefined;
    }

    async _removeMemberFromPipeline(memberId) {
        if (!this.pipeline.canEdit) return;
        try {
            await this._pipelines.removeFromPipeline(this.pipelineId, memberId);
            for (let sl of this.pipeline.stages) {
                const stageIndex = sl.members.findIndex(x => x.member.id === memberId);
                if (stageIndex < 0) continue;
                sl.members.splice(stageIndex, 1);
                break;
            }
        } catch (err) {
            console.log(err);
        }
    }

    async _reorderMembers(stageId, stageEl) {
        if (!this.pipeline.canEdit) return;
        try {
            const memberIds = [];
            stageEl.childNodes.forEach(n => {
                memberIds.push({ memberId: n.getAttribute('gs-id'), y: Number(n.getAttribute('gs-y')) });
            });
            memberIds.sort((a, b) => a.y - b.y);
            const orderedMemberIds = memberIds.map(x => x.memberId);
            await this._pipelines.orderMembersInStage(this.pipeline.id, stageId, orderedMemberIds, this.archivedAgents);
        } catch (err) {
            console.log(err);
        }
    }

    async addAgentChanged() {
        if (!this.pipeline.canEdit) return;
        try {
            if (!this.addAgent) return;
            if (this._memberFromPipeline(this.addAgent.id)) {
                this.addAgent = null;
				this._notifier.info('agent-already-in-pipeline');
                return;
            }
            const firstStage = this.pipeline && this.pipeline.stages ? this.pipeline.stages[0] : undefined;
            const member = await this._pipelines.addToPipeline(this.pipelineId, this.addAgent.id);
            firstStage.members.push({ member, daysExpectedInStage: firstStage.daysExpectedInStage, enteredStageDate: moment().toISOString(), isOverExpectedTime: false, percentageOfExpectedTimeInStage: 0 });
            let grid;
            this._grids.forEach(g => {
                if (g.el.id !== firstStage.id) return;
                grid = g;
            });
            // Add to the top
            grid.addWidget({ id: member.id, x: 0, y: 0, w: 1, h: 1 });
            this.addAgent = null;
        } catch (err) {
            console.log(err);
        }
    }

    _memberFromPipeline(memberId) {
        const backlogMember = this.backlog.find(x => x.id === memberId);
        if (backlogMember) return backlogMember;
        for (let sl of this.pipeline.stages) {
            const stageMember = sl.members.find(x => x.member.id === memberId);
            if (stageMember) return stageMember;
        }
        return null;
    }

    _memberStage(memberId) {
        for (let sl of this.pipeline.stages) {
            const stageMember = sl.members.find(x => x.member.id === memberId);
            if (stageMember) return sl;
        }
        return null;
    }

    pipelineAction(key) {
        switch (key) {
            case 'share-pipeline': this._sharePipeline(); break;
            case 'edit-pipeline': this._editPipeline(); break;
            case 'copy-pipeline': this._copyPipeline(); break;
            case 'add-stage': this._addStage(); break;
            case 'archived-agents': this.archivedAgents = true; this._load(); break;
            case 'current-agents': this.archivedAgents = false; this._load(); break;
            case this.VIEWS.standard:
            case this.VIEWS.compact:
            case this.VIEWS.minimized: this._changeView(key); break;
            case 'reorder-pipeline': this._reorderPipeline(); break;
        }
    }

    async _addStage() {
        if (!this.pipeline.canEdit) return;
        try {
            const model = { pipelineId: this.pipelineId, stage: {} };
            this._dialogService.open({ viewModel: EditStage, model, ignoreTransitions: true }).whenClosed(async(response) => {
                if (response.wasCancelled) return;

                const newStage = response.output.stage;
                newStage.members = [];
                newStage.backgroundColor = LpfnUtil.hexToRgbString(newStage.color, 0.05);
                newStage.placeholderColor = LpfnUtil.hexToRgbString(newStage.color, 0.5, '#D3D3D3');
                newStage.stageBackgroundColor = LpfnUtil.hexToRgbString(newStage.color, 0.08, '#D3D3D3');
                this._addActionsToStage(newStage);
                this.pipeline.stages.push(newStage);
                this.stageMaxHeightCss = undefined;
                if (this._grids) this.dispose();
                this._load();
            });
        } catch (err) {
            console.log(err);
        }
    }

    _addActionsToStage(sl) {
        sl.actions = [];
        if (this.pipeline.canReorderStageCards) sl.actions.push({ key: 'reorder-stage', name: 'reorder-stage' });
        if (this.pipeline.canEditStages) sl.actions.push({ key: 'edit-stage', name: 'edit-stage' });
        if (this.pipeline.canEditStages) sl.actions.push({ key: 'delete-stage', name: 'delete-stage' });
    }

    stageAction(sl, key) {
        switch (key) {
            case 'reorder-stage':
                this._reorderPipeline(sl);
                break;
            case 'edit-stage':
                this._editStage(sl);
                break;
            case 'delete-stage':
                this.deleteStage(sl);
                break;
        }
    }

    async _editStage(sl) {
        if (!this.pipeline.canEdit) return;
        try {
            const model = { pipelineId: this.pipelineId, stage: sl };
            this._dialogService.open({ viewModel: EditStage, model, ignoreTransitions: true }).whenClosed(async(response) => {
                if (response.wasCancelled) return;

                sl.name = response.output.stage.name;
                sl.expectedDaysInStage = response.output.stage.expectedDaysInStage;
                sl.color = response.output.stage.color;
                sl.backgroundColor = LpfnUtil.hexToRgbString(sl.color, 0.05);
                sl.placeholderColor = LpfnUtil.hexToRgbString(sl.color, 0.5, '#D3D3D3');
                sl.stageBackgroundColor = LpfnUtil.hexToRgbString(sl.color, 0.08, '#D3D3D3');

                sl.members.forEach(m => {
                    this._setAgentPipelineCard(m.member.id, undefined, m.metadata?.color, m.enteredStageDate, sl);
                });
            });
        } catch (err) {
            console.log(err);
        }
    }

    deleteStage(sl) {
        if (!this.pipeline.canEdit) return;
        const index = this.pipeline.stages.findIndex(x => x.id === sl.id);
        const model = { key: 'delete-stage', okButtonClass: 'btn-danger', messageObject: { name: sl.name } };
	    this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
	        if (response.wasCancelled) return;
			try {
				await this._pipelines.deleteStage(this.pipeline.id, sl.id);
                this.pipeline.stages.splice(index, 1);
			} catch (err) {
                this._notifier.errorText(err);
			}
        });
    }

    _initializeGrids() {
        if (!this._isAttached || !this.pipeline || !this.pipeline.stages.length) return;
        const config = {
            column: 1,
            minRow: 5,
            disableResize: true,
            acceptWidgets: true,
            cellHeight: this._cellHeight(this.view),
            acceptWidgets: (el) => { return true; },
            removable: '.lpfn-stage-trash',
        };
        if (!this.pipeline.canManageStageCards) {
            config.disableDrag = true;
            config.removable = false;
            config.acceptWidgets = false;
            config.acceptWidgets = (el) => { return false; };
            config.staticGrid = true; 
            config.removeable = undefined;
        }
        this._grids = GridStack.initAll(config, `.grid-stack.grid-stack-${this.id}`);
        GridStack.setupDragIn('.lpfn-pipeline-backlog,.lpfn-stage-item', { revert: 'invalid', scroll: false, appendTo: 'body', helper: 'clone' });
        this._grids.forEach((grid, i) => {
            const stage = this.pipeline.stages.find(x => x.id === grid.el.id);
            const items = [];
            let y = 0;
            if (stage.members) {
                stage.members.forEach(m => {
                    items.push({ id: m.member.id, x: 0, y: y++, w: 1, h: 1 });
                });
            }
            this._addEventsToGrid(grid, i);
            grid.load(items);
        });
    }

    async _sharePipeline() {
        if (!this.pipeline.canEdit) return;
        try {
            const model = { pipeline: this.pipeline };
            this._dialogService.open({ viewModel: SharePipeline, model, ignoreTransitions: true });
        } catch (err) {
            console.log(err);
        }
    }

    async _editPipeline() {
        if (!this.pipeline.canEdit) return;
        try {
            const model = { pipeline: this.pipeline };
            this._dialogService.open({ viewModel: EditPipeline, model, ignoreTransitions: true }).whenClosed(async(response) => {
                if (response.wasCancelled) return;
                this.stageMaxHeightCss = undefined;
                if (this._grids) this.dispose();
                await this._load();
                this._element.dispatchEvent(new CustomEvent('pipeline-edited', { bubbles: true, detail: this.pipeline }));
            });
        } catch (err) {
            console.log(err);
        }
    }

    async _copyPipeline() {
        if (!this.pipeline.canEdit) return;
        try {
            const model = { pipeline: this.pipeline };
            this._dialogService.open({ viewModel: CopyPipeline, model, ignoreTransitions: true }).whenClosed(async(response) => {
                if (response.wasCancelled) return;
				this._notifier.success('pipeline-copied');
            });
        } catch (err) {
            console.log(err);
        }
    }

    async _reorderPipeline(stage) {
        if (!this.pipeline.canEdit) return;
        try {
            this._dialogService.open({ viewModel: ReorderPipeline, model: { pipeline: this.pipeline, stage: stage }, ignoreTransitions: true }).whenClosed(async(response) => {
                if (response.wasCancelled) return;
                await this._load();
                if (response.output.orderBy) {
                    this.orderByKey = response.output.orderBy.translationKey;
                    this.sortDirection = response.output.sortDirection;
                } else {
                    this.orderByKey = undefined;
                    this.sortDirection = undefined;
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    _changeView(view) {
        try {
            this.view = view;
            if (!this._grids || !this.pipeline) return;
            this._grids.forEach(g => {
                g.cellHeight(this._cellHeight(view), true);
            });
        } catch (err) {
            console.log(err);
        }
    }

    _cellHeight(view) {
        switch (view) {
            case this.VIEWS.compact: return 90;
            case this.VIEWS.minimized: return 90;
            default: return 160 + (this.pipeline.showAgentProperties.length * 30);
        }
    }

    async purgeArchives() {
        try {
            const model = { key: 'purge-pipeline', okButtonClass: 'btn-danger', messageObject: { name: this.pipeline.name } };
            this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
                if (response.wasCancelled) return;
                try {
                    await this._pipelines.purgePipeline(this.pipeline.id);
                    this.archivedAgents = false;
                    await this._load();
                } catch (err) {
                    this._notifier.errorText(err);
                }
            });
        } catch (err) {
            console.log(err);
        }
    }
}