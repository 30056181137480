import { bindable } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Pipelines as PipelinesSvc } from 'services/pipelines';
import { DialogService } from 'aurelia-dialog';
import { ConfirmDialog } from 'common/dialogs/confirm/confirm-dialog';
import { EditPipeline } from './dialogs/edit-pipeline';
import { CopyPipeline } from './dialogs/copy-pipeline';
import { SharePipeline } from './dialogs/share-pipeline';
PLATFORM.moduleName('common/dialogs/confirm/confirm-dialog');
PLATFORM.moduleName('./dialogs/edit-pipeline');
PLATFORM.moduleName('./dialogs/copy-pipeline');
PLATFORM.moduleName('./dialogs/share-pipeline');

export class Pipelines {
	static inject = [EventAggregator, Security, PipelinesSvc, DialogService];
    @bindable memberId;
    @bindable showAddPipeline = false;

    _ea;
    _security;
    _pipelines;
    _dialogService;

    pipelines = [];
    _handlers = [];

    constructor(ea, security, pipelinesSvc, dialogService) {
        this._ea = ea;
        this._security = security;
        this._pipelines = pipelinesSvc;
        this._dialogService = dialogService;
    }

    attached() {
        this._load();
        this._handlers.push(this._ea.subscribe('dnd:didEnd', () => {
            window.setTimeout(() => this._savePipelineOrdering(), 0);
        }));
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    pipelineAction(key, pipeline, index) {
        switch (key) {
            case 'share':
                this.sharePipeline(pipeline);
                break;
            case 'edit':
                this.editPipeline(pipeline);
                break;
            case 'delete':
                this.deletePipeline(pipeline, index);
                break;
            case 'copy':
                this.copyPipeline(pipeline);
                break;
        }
    }

    async _savePipelineOrdering() {
        try {
            const orderedIds = [];
            this.pipelines.forEach(p => {
                orderedIds.push(p.id);
            });
            await this._pipelines.setPipelineOrdering(orderedIds);
        } catch (err) {
            console.log(err);
        }
    }

    async _load() {
        try {
            this.pipelines = await this._pipelines.pipelines(this.memberId);
            this.pipelines.forEach(p => this._setPipelineActions(p));
        } catch (err) {
            console.log(err);
        }
    }

    _setPipelineActions(pipeline) {
        pipeline.actions = [];
        if (this._security.isAdmin) pipeline.actions.push({ key: 'share', name: 'share-pipeline', icon: 'fa-duotone fa-handshake-simple' });
        if (pipeline.canEdit) pipeline.actions.push({ key: 'edit', name: 'edit-pipeline', icon: 'fa-duotone fa-pencil' });
        if (pipeline.canEdit) pipeline.actions.push({ key: 'copy', name: 'copy-pipeline', icon: 'fa-duotone fa-clone' });
        if (pipeline.canDelete) pipeline.actions.push({ key: 'delete', name: 'delete-pipeline', icon: 'fa-duotone fa-trash-alt' });
    }

    addPipeline() {
        const model = { pipeline: {} };
        this._dialogService.open({ viewModel: EditPipeline, model, ignoreTransitions: true }).whenClosed(response => {
            if (response.wasCancelled) return;
            this._setPipelineActions(response.output.pipeline);
            this.pipelines.push(response.output.pipeline);
        });
    }

    async sharePipeline(pipeline) {
        try {
            const model = { pipeline };
            this._dialogService.open({ viewModel: SharePipeline, model, ignoreTransitions: true });
        } catch (err) {
            console.log(err);
        }
    }

    async copyPipeline(pipeline) {
        try {
            const model = { pipeline };
            this._dialogService.open({ viewModel: CopyPipeline, model, ignoreTransitions: true }).whenClosed(response => {
                if (response.wasCancelled) return;
                this.pipelines.push(response.output.pipeline);
            });
        } catch (err) {
            console.log(err);
        }
    }

    async editPipeline(pipeline) {
        try {
            const model = { pipeline };
            this._dialogService.open({ viewModel: EditPipeline, model, ignoreTransitions: true }).whenClosed(async(response) => {
                if (response.wasCancelled) return;
                pipeline.name = response.output.pipeline.name;
                pipeline.description = response.output.pipeline.description;
                pipeline.color = response.output.pipeline.color;
                pipeline.showAgentProperties = response.output.pipeline.showAgentProperties;
            });
        } catch (err) {
            console.log(err);
        }
    }

    pipelineEdited(pipeline) {
        this.viewPipeline = pipeline;
    }

    deletePipeline(pipeline, index) {
        const model = { key: 'delete-ats-pipeline', okButtonClass: 'btn-danger', messageObject: { name: pipeline.name } };
	    this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
	        if (response.wasCancelled) return;
			try {
				await this._pipelines.deletePipeline(pipeline.id);
                this.pipelines.splice(index, 1);
			} catch (err) {
                console.log(err);
			}
        });
    }

    showPipeline(pipeline) {
        this.viewPipeline = pipeline;
    }

    hidePipeline() {
        this.viewPipeline = undefined;
        this._load();
    }
}