import { DialogController } from 'aurelia-dialog';
import { Pipelines } from 'services/pipelines';
import { AGENT_PROPERTY } from 'common/constants';
import { LevelNameValueConverter } from 'resources/value-converters/level-name';
import moment from 'moment';

export class ReorderPipeline {
    static inject = [DialogController, Pipelines, LevelNameValueConverter];
    dialogController;
    _pipelines;
    _levelNameConverter;

    orderByOptions = [];
    orderBy;
    sortDirection = 'asc';

    constructor(dialogController, pipelines, levelNameValueConverter) {
        this.dialogController = dialogController;
        this._pipelines = pipelines;
        this._levelNameConverter = levelNameValueConverter;

        this.orderByOptions = AGENT_PROPERTY.tagOptions();
        this.orderByOptions.unshift({ id: 'firstName', translationKey: 'profile-first-name', type: 'String' });
        this.orderByOptions.unshift({ id: 'lastName', translationKey: 'profile-last-name', type: 'String' });
        this.orderByOptions.unshift({ id: 'fullName', translationKey: 'name', type: 'String' });
        this.orderByOptions.push({ id: 'enteredStageDate', translationKey: 'entered-stage-date', type: 'Date', obj: 'root' });
        this.orderBy = this.orderByOptions[0].id;
    }

    activate(model) {
        this.pipeline = model.pipeline;
        this.stage = model.stage;
    }

    async reorder() {
        try {
            const reorder = [];
            this.pipeline.stages.forEach(sl => {
                if (this.stage && this.stage.id !== sl.id) return;
                const orderedMemberIds = this._sortedMemberIds(sl.members);
                if (orderedMemberIds.length === 0) return;
                reorder.push(this._pipelines.orderMembersInStage(this.pipeline.id, sl.id, orderedMemberIds));
            });
            await Promise.all(reorder);
            const option = this.orderByOptions.find(x => x.id === this.orderBy);
            const payload = this.stage ? {} : { orderBy: option, sortDirection: this.sortDirection };
            this.dialogController.ok(payload);
        } catch (err) {
            console.log(err);
        }
    }

    _sortedMemberIds(members) {
        if (!members || members.length === 0) return [];
        const option = this.orderByOptions.find(x => x.id === this.orderBy);
        members.sort((a, b) => {
            let aValue = option.obj === 'root' ? a[option.id] : a.member[option.id];
            let bValue = option.obj === 'root' ? b[option.id] : b.member[option.id];
            let valueA = this.sortDirection === 'asc' ? aValue : bValue;
            let valueB = this.sortDirection === 'asc' ? bValue : aValue;
            if (option.type === 'Date') {
                let valueAMoment = moment.utc(valueA);
                let valueBMoment = moment.utc(valueB);
                if (!valueAMoment.isValid()) return -1;
                if (!valueBMoment.isValid()) return 1;
                return valueAMoment.isBefore(valueBMoment) ? -1 : 1;
            } else if (option.type === 'Boolean') {
                if (valueA === valueB) return 0;
                if (valueA) return 1;
                return -1;
            } else if (option.type === 'Level') {
                let valueANumber = valueA ? valueA.replace(/^\D+/g, '') : -1;
                if (valueANumber === '') valueANumber = -1;
                let valueBNumber = valueB ? valueB.replace(/^\D+/g, '') : -1;
                return Number(valueANumber) - Number(valueBNumber);
            } else {
                return valueA.localeCompare(valueB);
            }
        });
        return members.map(x => x.member.id);
    }
}
