import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { Security } from 'common/security';
import { Pipelines } from 'services/pipelines';
import { I18n } from 'common/i18n';

export class SharePipeline {
    static inject = [DialogController, Pipelines, Security, I18n, NewInstance.of(ValidationController)];
    dialogController;
    validationController;
    _pipelines;
    security;
    _i18n;

    agent;
    canEdit = false;

    constructor(dialogController, pipelines, security, i18n, validationController) {
        this.dialogController = dialogController;
        this.security = security;
        this._pipelines = pipelines;
        this._i18n = i18n;

		this.validationController = validationController;
		this.validationController.addRenderer(new BootstrapFormValidationRenderer());

		ValidationRules
            .ensure('agent').required()
            .on(this);
    }

    activate(model) {
        this.pipeline = model.pipeline;
        this._loadCurrentShares();
    }

    async _loadCurrentShares() {
        try {
            this.shares = await this._pipelines.pipelineShares(this.pipeline.id);
        } catch (err) {
            console.log(err);
        }
    }

    async toggleCanEdit(share) {
        try {
            await this._pipelines.sharePipeline(this.pipeline.id, share.agent.id, !share.canEdit);
        } catch (err) {
            console.log(err);
        }
    }

    async removeShare(index, share) {
        try {
            await this._pipelines.removePipelineShare(this.pipeline.id, share.agent.id);
            this.shares.splice(index, 1);
        } catch (err) {
            console.log(err);
        }
    }

    setCanEdit(canEdit) {
        this.canEdit = canEdit;
    }

    async save() {
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;

            await this._pipelines.sharePipeline(this.pipeline.id, this.agent.id, this.canEdit);
            this.agent = undefined;
            this.canEdit = false;
            window.setTimeout(() => this.validationController.reset(), 0);
            this._loadCurrentShares();
        } catch (err) {
            console.log(err);
        }
    }
}
