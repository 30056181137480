import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { Security } from 'common/security';
import { Pipelines } from 'services/pipelines';
import { I18n } from 'common/i18n';
import { AGENT_PROPERTY } from 'common/constants';

export class CopyPipeline {
    static inject = [DialogController, Pipelines, Security, I18n, NewInstance.of(ValidationController)];
    dialogController;
    validationController;
    _pipelines;
    security;
    _i18n;

    name;
    description;
    color;
    code;
    showAgentProperties;
    
    agentPropertyOptions = AGENT_PROPERTY.tagOptions();
    agentPropertyTagOptions = [];
    agentProperties = [];
    agentPropertyTags = [];

    constructor(dialogController, pipelines, security, i18n, validationController) {
        this.dialogController = dialogController;
        this.security = security;
        this._pipelines = pipelines;
        this._i18n = i18n;

		this.validationController = validationController;
		this.validationController.addRenderer(new BootstrapFormValidationRenderer());

		ValidationRules
            .ensure('name').required()
            .on(this);
    }

    activate(model) {
        this.pipeline = model.pipeline;
        this.name = this.pipeline.name + ' - Copy';
        this.description = this.pipeline.description;
        this.showAgentProperties = this.pipeline.showAgentProperties || [];
        this.color = this.pipeline.color;

        this.agentPropertyTagOptions = [];
        this.agentPropertyOptions.forEach(o => {
            if (!o.translation) o.translation = this._i18n.tr(o.translationKey);
            this.agentPropertyTagOptions.push(o.translation);
        });

        this.showAgentProperties.forEach(ap => {
            const option = this.agentPropertyOptions.find(x => x.id === ap);
            if (!option) return;
            this.agentPropertyTags.push(option.translation);
        });
    }

    agentPropertiesChanged(tags) {
        const properties = [];
        tags.forEach(t => {
            let option = this.agentPropertyOptions.find(x => x.translation.toLowerCase() === t.toLowerCase());
            if (!option) return;
            properties.push(option.id);
        });
        this.showAgentProperties = properties;
    }

    async save() {
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;

            const pipeline = await this._pipelines.copyPipeline(this.pipeline.id, this.name, this.description, this.color, this.showAgentProperties);
            this.dialogController.ok({ pipeline });
        } catch (err) {
            console.log(err);
        }
    }
}
