import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { Pipelines } from 'services/pipelines';

export class EditStage {
    static inject = [DialogController, NewInstance.of(ValidationController), Pipelines];
    dialogController;
    validationController;
    _pipelines;

    name;
    expectedDaysInStage;
    color;
    
    constructor(dialogController, validationController, pipelines) {
        this.dialogController = dialogController;

		this.validationController = validationController;
		this.validationController.addRenderer(new BootstrapFormValidationRenderer());

		ValidationRules
            .ensure('name').required()
            .on(this);

        this._pipelines = pipelines;
    }

    activate(model) {
        this.pipelineId = model.pipelineId;
        this.stage = model.stage;
        this.titleKey = this.stage.id ? 'edit-stage' : 'add-stage';
        this.name = '';
        this.expectedDaysInStage;
        this.color = '';
        if (this.stage.id) {
            this.name = this.stage.name;
            this.expectedDaysInStage = this.stage.expectedDaysInStage;
            this.color = this.stage.color;
        }
    }

    async save() {
        if (this.saving) return;
        try {
            this.saving = true;
            const v = await this.validationController.validate();
            if (!v.valid) return;

            let stage;
            if (this.stage.id) {
                stage = await this._pipelines.editStage(this.pipelineId, this.stage.id, this.name, this.expectedDaysInStage, this.color);
            } else {
                stage = await this._pipelines.addStage(this.pipelineId, this.name, this.expectedDaysInStage, this.color);
            }
            this.dialogController.ok({ stage });
        } catch (err) {
            console.log(err);
        } finally {
            this.saving = false;
        }
    }
}
